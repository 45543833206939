<template>
  <v-toolbar
    flat
    height="80"
  >
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="deep-purple accent-4"
    />
    <!-- <span
      style="font-size: 14px"
      class="mr-1"
    >
      Search professional:
    </span> -->
    <v-text-field
      flat
      :value="value"
      prepend-inner-icon="mdi-magnify"
      :label="$t('actions|search_professional')"
      rounded
      outlined
      hide-details
      dense
      class="shrink"
      @input="event => this.$emit('search-professional', event)"
    />
    <v-spacer />
    <v-btn
      color="primary"
      rounded
      small
      dark
      class="mb-2"
      @click="$emit('add-professional')"
    >
      <v-icon
        small
        class="mr-2 mt-1"
      >
        mdi-account-plus-outline
      </v-icon>
      {{ $t('actions|add_new_professional') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        arrowDown: mdiChevronDown
      }
    }
  },
  methods: {}
}
</script>
