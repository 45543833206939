<template>
  <v-container
    fluid
    fill-height
    class="pa-3 d-block background"
  >
    <v-card
      height="100%"
      color="lightBackground"
      class="pa-0"
      style="overflow-y: auto;"
    >
      <ProfessionalsFilterBar
        style="position: sticky; top: 0px; z-index: 1;"
        @add-professional="addProfessional()"
        @search-professional="searchProfessional"
      />
      <v-row
        v-if="professionals.length"
        no-gutters
        align="start"
        justify="start"
        class="ma-2"
      >
        <div
          v-for="(professional, index) in professionals"
          :key="index"
          class="mx-3 my-3"
        >
          <ProfessionalsCard
            :professional="professional"
          />
        </div>
      </v-row>
      <v-row
        v-else
      >
        <div class="ml-4 mt-4 pl-4">
          {{ $t('expressions|no_professionals_added') }}
        </div>
      </v-row>
      <AddProfessional
        ref="AddProfessional"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import ProfessionalsFilterBar from './ProfessionalsFilterBar.vue'
import ProfessionalsCard from './ProfessionalsCard.vue'
import AddProfessional from '../../components/dialogs/AddProfessional.vue'

const sortByUpdated = (items) => items.sort((a, b) => moment.utc(b.createdAt).diff(moment.utc(a.createdAt)))

export default {
  components: {
    ProfessionalsFilterBar,
    ProfessionalsCard,
    AddProfessional
  },
  data () {
    return {
      search: '',
      selection: localStorage.getItem('clientSectionView') || 'All'
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    professionals () {
      let professionalsArray = []
      if (this.account.myProfessionals.accounts.length) {
        professionalsArray.push(...this.account.myProfessionals.accounts)
      }
      if (this.account.myProfessionals.company.length) {
        professionalsArray.push(...this.account.myProfessionals.company)
      }
      if (this.account.myProfessionals.groups.length) {
        professionalsArray.push(...this.account.myProfessionals.groups)
      }
      if (this.account.myProfessionals.pending.length) {
        professionalsArray.push(...this.account.myProfessionals.pending)
      }
      if (this.search && this.search.length) {
        return professionalsArray.filter((p) => {
          if (p.accountName || p.accountData) {
            return p.accountName?.toLowerCase().includes(this.search.toLowerCase()) || p.accountData?.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
            p.accountData?.lastName.toLowerCase().includes(this.search.toLowerCase())
          }
          if (p.companyData) {
            return p.companyData.email?.toLowerCase().includes(this.search.toLowerCase()) || p.companyData.companyName?.toLowerCase().includes(this.search.toLowerCase())
          }
          if (p.groupName || p.groupEmail) {
            return p.groupEmail?.toLowerCase().includes(this.search.toLowerCase()) || p.groupName?.toLowerCase().includes(this.search.toLowerCase())
          }
        })
      }
      return professionalsArray
    },
    searchedClients () {
      sortByUpdated(this._searchedClients)
      return this._searchedClients
    }
  },
  methods: {
    ...mapActions({
    }),
    changeView (item) {
      this.selection = item
      localStorage.setItem('clientSectionView', item)
    },
    addProfessional () {
      this.$refs.AddProfessional.dialog = true
    },
    searchProfessional (search) {
      this.search = search
    }
  }
}
</script>
